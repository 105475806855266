import React from 'react'
import { RichText } from '../Shared/RichText/RichText'
import * as styles from './notFound.module.scss'
import { Link } from 'gatsby'
import { useLocalPath } from '../hooks/useLocalPath'
const NotFound = ({data}) => {
    const{ description, cta, title} = data
  return (
    <div className='container container-lg'>
            <h1 className={styles.title}>
                {title}
            </h1>
            <div className={styles.desc}>
                <RichText content={ description} />
            </div>
            <div>
                <Link to = {useLocalPath(cta.slug)}> <button className='red-button'>{cta.title}</button></Link>
            </div>
        </div>
  )
}

export default NotFound